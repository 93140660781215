import { createAction } from 'redux-actions';
import * as ActionTypes from './ActionTypes';

export const setProject = createAction(ActionTypes.SET_PROJECT);
export const setLang = createAction(ActionTypes.SET_LANG);
export const setIsDev = createAction(ActionTypes.SET_IS_DEV);

const initialState = {
	projectId: '5',
	language: 'ko',
	isDev: false,
};

function setting(state = initialState, action) {
	switch (action.type) {
		case ActionTypes.SET_PROJECT:
			return {
				...state,
				projectId: action.payload,
			};
		case ActionTypes.SET_LANG:
			return {
				...state,
				language: action.payload,
			};
		case ActionTypes.SET_IS_DEV:
			return {
				...state,
				isDev: action.payload,
			};
		default:
			return state;
	}
}

export default setting;
